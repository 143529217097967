import React, { useState, useEffect } from 'react';
import SimpleTable from './SimpleTable';

const App = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("Morpho Vaults Optimal Allocation");

  useEffect(() => {
    const fetchData = () => {
      fetch('/morpho_vaults_results.json')
        .then(response => response.json())
        .then(data => setData(data));
    };

    fetchData(); // Fetch immediately on mount

    const intervalId = setInterval(fetchData, 120000); // Fetch every minute

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  return (
    <div style={{ 
      margin: 0,
      padding: 0,
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '100%',
        padding: '20px',
        boxSizing: 'border-box',
      }}>
        <h1 style={{ fontSize: '24px', marginBottom: '20px', textAlign: 'center' }}>Morpho Vaults Asset Allocation</h1>
        {data.map((vault, index) => (
          <div key={index} style={{ marginBottom: '40px', width: '100%' }}>
            <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>{vault.name} ({vault.chain})</h2>
            <p>Old Avg APY ({vault.avg_days}d): {(vault.old_apy * 100).toFixed(3)}% | New Avg APY ({vault.avg_days}d): {(vault.new_apy * 100).toFixed(3)}%</p>
            <p>Absolute Change in USD: ${vault.abs_change_usd.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
            <SimpleTable data={vault.old_markets} />
            <SimpleTable data={vault.new_markets} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;